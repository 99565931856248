// @flow
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react'
import { ThemeConsumer, styles, Button, Heading, Icons, Tooltip } from '@mlcl-digital/mlcl-design'
import { object, shape, string, oneOfType, bool, number } from 'prop-types'
import get from 'lodash/get'
import { createEvent } from '../../../../../utils/telemetry'

// components.
import LinkComponent from '../../../../atoms/Link'

// styles.
import tileStyles from './tile.styles'

// utils
import { renderTextField } from '../../../../../utils/sitecoreUtils'
import { shouldRenderNativeLink, isFeatureEnabledForCP } from '../../../../../utils/commonUtils'
import history from '../../../../../utils/browserHistory'
import {
  getCPINotEligibleDesc,
  getCPIUpliftNotEligibleDesc,
} from '../../../../../utils/alterationRules'
import { isFeatureEnabled } from '../../../../../utils/featureToggling'

// constants.
import {
  INCOME_PROTECTION_SR_ID,
  INCOME_PROTECTION_PLATINUM_ID,
  INCOME_PROTECTION_STANDARD_ID,
  BUSINESS_EXPENSE,
  BUSINESS_EXPENSE_PLATINUM,
  POLICY_BENEFIT_PTD,
  INCOME_PROTECTION_PSR_ID,
  INCOME_PROTECTION_PLUS_ID,
  INCOME_PROTECTION_SSR_ID,
  INCOME_PROTECTION_PHI_STD_2020_ID,
  INCOME_PROTECTION_SG_STD_2020_ID,
  INCOME_PROTECTION_PHI_PLAT_2020_ID,
  INCOME_PROTECTION_SG_PLAT_2020_ID,
} from '../../../../../constants/policies'
import {
  REJECT_CPI_LANDING_PAGE,
  INFLATION_PROOFING_LANDING_PAGE,
} from '../../../../../constants/routes'
import { ALTERATION_TYPES } from '../../../../../constants/alterations'

// actions
import { actionCreators } from '../../../../../actions'

// selectors
import { getIsAllAltsRules } from '../../../../../selectors/alterations'
import { getIsNotEligibleForInflationProofing } from '../../../../../selectors/alterationRules'
import { getMasterData, getConfig } from '../../../../../selectors/common.selectors'

const { IconHeartCheck32, IconIncome32, IconDisability32, IconInfo16 } = Icons

const TileContainer = styled('div')(({ bgVariation, alignVariation, tileWidth }) =>
  tileStyles.tile(bgVariation, alignVariation, tileWidth)
)
const IconContainer = styled('div')(tileStyles.iconContainer)
const InfoIcon = styled(IconInfo16)(tileStyles.infoIcon)
const TooltipContainer = styled('div')(tileStyles.tooltipContainer)
const TooltipContent = styled(Tooltip)(tileStyles.tooltipContent)
const InflationProofingBtn = styled(Button)(({ isInflationProofingUplift }) =>
  isInflationProofingUplift ? tileStyles.inflationProofingBtn : {}
)

const TrackedLink = props => {
  const trackClick = () => {
    const hrefActions = {
      'mental-health-navigator': 'Mental Health Navigator',
      'best-doctors': 'Best Doctors',
      support: 'Contact MLCL',
      PolicyAnniversary: 'Policy anniversary button on dashboard',
      RejectCPI: 'Reject CPI Dashboard',
    }

    if (props.field && props.field.value && props.field.value.href) {
      const uri = props.field.value.href.split('/').pop()
      const actionTxt = hrefActions[uri] || ''

      if (actionTxt) {
        const tagEvent = createEvent({
          GA: {
            category: 'Customer Policy Anniversary',
            action: actionTxt,
          },
          Splunk: {
            attributes: {
              'workflow.name': `Customer Policy Anniversary - ${actionTxt}`,
            },
          },
        })
        tagEvent.end()
      }
    }
  }
  return <Link {...props} onClick={trackClick} />
}
const TileActionLink = styled(TrackedLink)(({ theme, bgVariation }) =>
  styles.ButtonStyles.base({
    theme,
    variant: bgVariation === 'white' ? 'secondary' : 'secondaryWithOutline',
    size: 'small',
  })
)
const TileActionLinkWrapper = ThemeConsumer(TileActionLink, 'button')

const getBenefitIcon = benefitCode => {
  if (benefitCode === POLICY_BENEFIT_PTD) return <IconDisability32 />
  if (
    benefitCode === INCOME_PROTECTION_SR_ID ||
    benefitCode === INCOME_PROTECTION_PLATINUM_ID ||
    benefitCode === INCOME_PROTECTION_STANDARD_ID ||
    benefitCode === BUSINESS_EXPENSE ||
    benefitCode === BUSINESS_EXPENSE_PLATINUM ||
    benefitCode === INCOME_PROTECTION_PSR_ID ||
    benefitCode === INCOME_PROTECTION_PLUS_ID ||
    benefitCode === INCOME_PROTECTION_SSR_ID ||
    benefitCode === INCOME_PROTECTION_PHI_STD_2020_ID ||
    benefitCode === INCOME_PROTECTION_SG_STD_2020_ID ||
    benefitCode === INCOME_PROTECTION_PHI_PLAT_2020_ID ||
    benefitCode === INCOME_PROTECTION_SG_PLAT_2020_ID
  )
    return <IconIncome32 />
  return <IconHeartCheck32 />
}

const renderLink = (anchor: object, bgVariation: string) => {
  const href = get(anchor, 'value.href', '')

  const linkClicked = () => {
    const btnTxt = get(anchor, 'value.text', '')

    if (btnTxt) {
      const tagEvent = createEvent({
        GA: {
          category: 'Customer Policy Anniversary',
          action: `CP reject CPI - ${btnTxt}`,
        },
        Splunk: {
          attributes: {
            'workflow.name': `Customer Policy Anniversary - ${btnTxt}`,
          },
        },
      })
      tagEvent.end()
    }
  }

  if (!shouldRenderNativeLink(href)) {
    const target = get(anchor, 'value.target')
    return (
      <LinkComponent
        variant={bgVariation === 'white' ? 'secondary' : 'secondaryWithOutline'}
        size="small"
        to={href}
        onClick={linkClicked}
        {...(target ? { target } : null)}
        testid="customer-policy-aniv-link"
      >
        {renderTextField(get(anchor, 'value.text', ''))}
      </LinkComponent>
    )
  }
  return <TileActionLinkWrapper bgVariation={bgVariation} field={anchor} />
}

const Tile = ({ fields, bgVariation, alignVariation, isBenefitData, tileWidth }) => {
  const dispatch = useDispatch()
  const altsErrorModal = useSelector(state => get(state, 'modal.altsErrorModal', {}))
  const alterations = useSelector(state => state.alterations)
  const featureControlSwitch = useSelector(state =>
    get(state, 'masterList.data.featureControlSwitch', [])
  )
  const isAllAltsRules = useSelector(getIsAllAltsRules)
  const masterData = useSelector(getMasterData)
  const isNotEligibleForInflationProofing = useSelector(getIsNotEligibleForInflationProofing)
  const config = useSelector(getConfig)
  const isInflationProofingUplift = useMemo(
    () => isFeatureEnabled('InflationProofingUplift', config.FEATURES),
    []
  )

  const portfolioRules = get(alterations, 'rules.businessData.assesment', {})
  const policiesInRules = get(alterations, 'rules.businessData.policies', [])
  const { TileCTA, TileDesc, TileText, benefitType, TileName } = fields
  useEffect(() => {
    dispatch(actionCreators.initModal('altsErrorModal'))
  }, [])
  const inEligibleForCPIMsg = useMemo(
    () =>
      isInflationProofingUplift
        ? getCPIUpliftNotEligibleDesc({
            alterationsModalMessages: masterData.alterationMessages,
            portfolioRules,
            isAdviserPortal: false,
            policiesInRules,
          })
        : getCPINotEligibleDesc({
            alterationsModalMessages: masterData.alterationsModalMessages,
            portfolioRules,
            isAdviserPortal: false,
            policiesInRules,
          }),
    [portfolioRules]
  )

  const handleClick = useCallback(
    tileName => {
      switch (tileName) {
        case 'InflationProofing': {
          // setting alteration type selected by user in alterations state, used to make sure user
          // can't enter in middle of the flow
          dispatch(actionCreators.setAlterationTypeSelectedByUser(ALTERATION_TYPES.REJECT_CPI))
          // setting alteration type in quote entity(createQuote state),
          // used in landing page analytics as well at other pages
          dispatch(actionCreators.setAlterationType(ALTERATION_TYPES.REJECT_CPI))
          const tagEvent = createEvent({
            GA: {
              category: 'Customer Policy Anniversary',
              action: 'Reject CPI Dashboard',
            },
            Splunk: {
              attributes: {
                'workflow.name': 'Customer Policy Anniversary - Reject CPI Dashboard',
              },
            },
          })
          tagEvent.end()
          history.push(
            isInflationProofingUplift ? INFLATION_PROOFING_LANDING_PAGE : REJECT_CPI_LANDING_PAGE
          )
          break
        }
        case 'LifeInsuranceCalculator': {
          const tagEvent = createEvent({
            GA: {
              category: 'CP-LearnMoreInfoHub',
              action: 'Info Hub - Learn More',
            },
            Splunk: {
              attributes: {
                'workflow.name': 'Customer clicks info hub learn more',
              },
            },
          })
          tagEvent.end()
          history.push(TileCTA?.value?.href)
          break
        }
        default:
      }
    },
    [alterations.rules, altsErrorModal.description]
  )

  const renderCTA = useCallback(
    (anchor: object) => {
      const tileName = get(TileName, 'value')
      switch (tileName) {
        case 'InflationProofing': {
          return (
            <div>
              <InflationProofingBtn
                size="small"
                variant="secondaryWithOutline"
                onClick={() => handleClick(tileName)}
                isLoading={!isAllAltsRules}
                disabled={isNotEligibleForInflationProofing}
                isInflationProofingUplift={isInflationProofingUplift}
              >
                {renderTextField(get(anchor, 'value.text', ''))}
              </InflationProofingBtn>
              {isNotEligibleForInflationProofing && (
                <TooltipContainer>
                  <span data-tip data-for="inflationProofingTooltip">
                    <InfoIcon />
                  </span>
                  <TooltipContent id="inflationProofingTooltip">
                    {inEligibleForCPIMsg.description}
                  </TooltipContent>
                </TooltipContainer>
              )}
            </div>
          )
        }
        case 'LifeInsuranceCalculator': {
          return (
            <div>
              <Button
                size="small"
                variant="secondaryWithOutline"
                onClick={() => handleClick(tileName)}
              >
                {renderTextField(anchor?.value?.text || '')}
              </Button>
            </div>
          )
        }
        default: {
          return renderLink(TileCTA, bgVariation)
        }
      }
    },
    [isAllAltsRules]
  )

  const renderTileFeatureEnabled = TileNameString => {
    switch (get(TileNameString, 'value', '')) {
      case 'InflationProofing':
        return isFeatureEnabledForCP(featureControlSwitch, 'altsEnableRejectCPI')
      case 'SmokerStatus':
        return isFeatureEnabledForCP(featureControlSwitch, 'altsEnableUpdateSmokerStatus')
      default:
        return true
    }
  }

  return (
    renderTileFeatureEnabled(TileName) && (
      <>
        <TileContainer
          tileWidth={tileWidth}
          alignVariation={alignVariation.toLowerCase()}
          bgVariation={bgVariation.toLowerCase()}
          data-testid="tile-container"
        >
          {benefitType && <IconContainer>{getBenefitIcon(benefitType)}</IconContainer>}
          <Heading variant="h3" size="medium">
            {isBenefitData ? TileText : <Text field={TileText} />}
          </Heading>
          <div>{isBenefitData ? TileDesc : <RichText field={TileDesc} />}</div>
          {TileCTA && renderCTA(TileCTA)}
        </TileContainer>
      </>
    )
  )
}
Tile.propTypes = {
  fields: shape({
    TileCTA: object,
    TileDesc: oneOfType([string, object]),
    TileText: oneOfType([string, object]),
    benefitType: string,
  }).isRequired,
  alignVariation: string,
  bgVariation: string,
  isBenefitData: bool,
  tileWidth: number,
}

Tile.defaultProps = {
  alignVariation: 'center',
  bgVariation: 'dark',
  isBenefitData: false,
  tileWidth: 25,
}

export default Tile
