/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
// @flow
/* eslint-disable max-len */
import React, { Fragment, Component } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import { Loader } from '@mlcl-digital/mlcl-design'

// redux.
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isEqual from 'lodash/isEqual'
import { actionCreators } from '../../../../actions'

// component.
import PersonalStatementLayout from '../../../molecules/PersonalStatementLayout'
import HeadingComponent from '../../../atoms/Heading'
import LifeInsuredDetailsComponent from '../../../organisms/LifeInsuredDetails'
import WithLoader from '../../../molecules/WithLoader'
import PSChangeModalComponent from '../../../organisms/CompletionMethod/components/PSChangeModal'
import { getUREAssignedParty, handleUREByOtherParty } from '../../../../utils/quoteUtils'

// styles.
import styles from './teleunderwriter.styles'
import { space, colours } from '../../../../styles'

// constants
import { CUSTOMER_TELE_UNDERWRITER_UNDERWRITING_PAGE } from '../../../../constants/personalStatement'
import {
  NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION,
  NAVIGATION_CUSTOMER_PERSONAL_STATEMENT,
} from '../../../../constants/navigation'

// utils
import { reduceAuthorableFields, renderTextField } from '../../../../utils/sitecoreUtils'
import history from '../../../../utils/browserHistory'
import { universalCookie, getBancsCustomerNumber, getToken } from '../../../../utils/cookieUtils'
import { OKTA_COOKIE_NAME, OKTA_SCOPE_NAME, SCOPE_TYPE_TELE } from '../../../../constants/okta'

// styled components
const FormCaption = styled(HeadingComponent)(styles.caption)
const Message = styled('p')(styles.message)

// proptypes
type TeleUnderwriterPropTypes = {
  // sitecore editable labels
  fields: Object,
  // dispatchable actions
  actions: {
    changeNavigationType: Function,
    fetchQuoteCollection: Function,
    getAllProducts: Function,
    decodeToken: Function,
    getMasterList: Function,
    resetUREData: Function,
    resetCreateQuote: Function,
  },
  // quote fetching status
  isQuoteCollectionLoading: boolean,
  // change method modal
  isOpenUWChangeModal: boolean,
  createQuote: object,
  masterList: Object,
  // config object for all the constants
  config: Object,
}

export class Teleunderwriter extends Component<TeleUnderwriterPropTypes> {
  constructor(props) {
    super(props)
    this.queryParams = queryString.parse(get(history, 'location.search', ''))
  }

  componentDidMount() {
    const {
      actions: {
        changeNavigationType,
        getAllProducts,
        fetchQuoteCollection,
        updateQuoteCollection,
        getMasterList,
        resetUREData,
        resetCreateQuote,
        decodeToken,
        setOktaBancsCustomerNo,
      },
      createQuote,
      masterList,
    } = this.props

    const quoteCollectionId = get(this.queryParams, 'quoteCollectionId')
    const { access_token, scope } = queryString.parse(history.location.hash)
    if (quoteCollectionId) {
      // setting bancsCustomerNo in okta store so that it is retained during page refresh
      // and this will also make getBancsCustomerNo method working
      setOktaBancsCustomerNo(quoteCollectionId)
      window.location.replace(this.getAuthURL())
    }

    if (access_token && scope === SCOPE_TYPE_TELE) {
      universalCookie.set(OKTA_COOKIE_NAME, access_token)
      universalCookie.set(OKTA_SCOPE_NAME, scope)
      decodeToken(access_token)
    }

    if (getBancsCustomerNumber() && getToken()) {
      changeNavigationType(NAVIGATION_CUSTOMER_PERSONAL_STATEMENT)
      resetUREData()
      resetCreateQuote()
      if (!this.hasPolicy()) {
        getAllProducts(dispatch => {
          dispatch(
            fetchQuoteCollection(getBancsCustomerNumber(), (err, dataReceived) => {
              /**
               * callback to update the underwriting details in case there are multiple quotes having same enquiryId,
               * where one has URE complete and the other(active quote) has MY LINK.
               * This is done to enable the customer to retrieve the blocked application.
               */
              if (dataReceived && dataReceived.quotes.length > 1) {
                const { quotes, quoteIDToAction } = dataReceived
                const activeQuote = quotes.find(quote => quote.quoteId === quoteIDToAction)
                const activeQuoteIndex = quotes.findIndex(
                  quote => quote.quoteId === quoteIDToAction
                )
                let underwritingDetails = {}
                quotes.forEach(quote => {
                  if (
                    quote.quoteId !== activeQuote.quoteId &&
                    quote.underwritingDetails.enquiryId ===
                      activeQuote.underwritingDetails.enquiryId &&
                    !isEqual(quote.underwritingDetails, activeQuote.underwritingDetails) &&
                    !activeQuote.underwritingDetails.status &&
                    quote.underwritingDetails.status
                  ) {
                    underwritingDetails = quote.underwritingDetails
                  }
                })
                dataReceived.quotes[activeQuoteIndex].underwritingDetails = {
                  ...(underwritingDetails.enquiryId
                    ? underwritingDetails
                    : activeQuote.underwritingDetails),
                  underwritingMethod: activeQuote.underwritingDetails.underwritingMethod,
                }
                dataReceived.quotes[activeQuoteIndex].type = 'DRAFT_APPLN'
                updateQuoteCollection(dataReceived, true)
              }
            })
          )
        })
      }
      // Get Master Data
      if (isEmpty(masterList.data)) getMasterList()
    } else {
      changeNavigationType(NAVIGATION_CUSTOMER_METHOD_OF_COMPLETION)
    }

    // show app has beeen assigned to other party
    handleUREByOtherParty(createQuote)
  }

  componentWillReceiveProps(nextProps) {
    if (
      // eslint-disable-next-line react/destructuring-assignment
      getUREAssignedParty(this.props.createQuote) !== getUREAssignedParty(nextProps.createQuote)
    ) {
      handleUREByOtherParty(nextProps.createQuote)
    }
  }

  // eslint-disable-next-line react/sort-comp
  hasPolicy() {
    const { createQuote } = this.props
    const { activeIndex, quotes } = createQuote
    const hasPolicy = get(quotes, `${activeIndex}.policyStructure`, []).length
    return hasPolicy
  }

  // TODO: Investigate using okta auth lib. According to docs, its configured to auto renew the token.
  getAuthURL = () => {
    const { config } = this.props
    const authURL = `${config.MLCL_OKTA_API}/oauth2/${config.MLCL_TELE_OKTA_AUTH_SERVER_ID}/v1/authorize?idp=${config.MLCL_TELE_OKTA_IDP}&client_id=${config.MLCL_TELE_OKTA_CLIENT_ID}&response_type=token&scope=tele_underwriter&redirect_uri=${config.MLCL_TELE_OKTA_REDIRECT_URI}&state=${config.MLCL_TELE_OKTA_STATE}&nonce=nonce`
    return authURL
  }

  handleSubmit = () => {
    history.push(CUSTOMER_TELE_UNDERWRITER_UNDERWRITING_PAGE)
  }

  handleUWCloseModal = () => {
    const {
      actions: { closeUWMethodChangeModal },
    } = this.props
    closeUWMethodChangeModal()
  }

  render() {
    const { fields, isQuoteCollectionLoading, isOpenUWChangeModal, createQuote, config } =
      this.props
    const { lifeInsureHeading, lifeInsureSubHeading } = fields

    const { activeIndex, quotes, isFetchingData } = createQuote
    const hasPolicy = get(quotes, `${activeIndex}.policyStructure`, []).length

    return (
      <WithLoader
        loaderProps={{ loaderContent: renderTextField(fields.SaveLoaderMessage) }}
        isLoading={isFetchingData}
        childrenBackgroundColor={colours.white}
        overlay
      >
        <PersonalStatementLayout fields={fields} config={config}>
          {!hasPolicy || isQuoteCollectionLoading || !getBancsCustomerNumber() || !getToken() ? (
            <Loader spinnerSize={Number(space(10))} borderSize={Number(space(0.5))} />
          ) : (
            <Fragment>
              <FormCaption size={3}>{renderTextField(lifeInsureHeading)}</FormCaption>
              <Message>{renderTextField(lifeInsureSubHeading)}</Message>
              <LifeInsuredDetailsComponent
                isCustomer
                fields={reduceAuthorableFields(fields)}
                afterSubmission={this.handleSubmit}
              />
            </Fragment>
          )}
        </PersonalStatementLayout>
        {isOpenUWChangeModal && (
          <PSChangeModalComponent
            fields={reduceAuthorableFields(fields)}
            handleUWCloseModal={this.handleUWCloseModal}
          />
        )}
      </WithLoader>
    )
  }
}

export const mapStateToProps = ({
  myLink: { isQuoteCollectionLoading, showModal },
  createQuote,
  masterList,
  config,
  productRules,
}) => ({
  isQuoteCollectionLoading,
  isOpenUWChangeModal: showModal,
  createQuote,
  masterList,
  config,
  productRules,
})

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Teleunderwriter)
