import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'

// components
import { Heading, Chip, Tooltip, RadioGroup, Radio } from '@mlcl-digital/mlcl-design'
import Icon from '@mlcl-digital/mlcl-design/lib/base/Icon'

// types
import {
  PremiumCalculatorQuotePreviewFields,
  PreviewQuote,
} from '../../../../../types/components/PremiumCalculator'
import { SitecoreField } from '../../../../../types/sitecore'

// utils
// @ts-expect-error file not in typescript
import { renderTextField } from '../../../../../utils/sitecoreUtils'
// @ts-expect-error file not in typescript
import { idMaker } from '../../../../../utils/formUtils'
import { createEvent } from '../../../../../utils/telemetry'

// styles
import styles from './policy.styles'
import Cover from '../Cover'

// constants
import { REPORTING_PAYMENT_FREQUENCY_OPTIONS } from '../../../../../constants/forms'
import { POLICY_FREQUENCY_ANNUAL } from '../../../../../constants/policies'

// actions
// @ts-expect-error file not in typescript
import { actionCreators } from '../../../../../actions'

type PolicyProps = {
  fields: PremiumCalculatorQuotePreviewFields
  policyPreview: PreviewQuote
}

const PolicyContainer = styled('div')(styles.policyContainer)
const PolicyHeading = styled('div')(styles.heading)
const PaymentDetails = styled('div')(styles.paymentDetails)
const PaymentFrequency = styled('div')(styles.paymentFrequency)
const PolicySection = styled('div')(styles.policySection)
const CoverSection = styled('div')(styles.coverSection)
const PremiumSaved = styled('div')(styles.premiumSaved)
const TooltipContainer = styled('span')(styles.tooltipContainer)
const NoAdjustmentContainer = styled('div')(styles.noAdjustmentContainer)
const ErrorMessage = styled('div')(styles.errorMessage)
const SubHeading = styled('div')(styles.subHeading)
const WaiverWrapper = styled('div')(styles.waiverWrapper)

const Policy = ({ fields, policyPreview }: PolicyProps) => {
  const dispatch = useDispatch()
  const { policy } = policyPreview
  const {
    isPolicyAltered,
    waiverBenefit,
    bancsPolicyNo,
    policyInstanceNo,
    paymentFrequencyOptions,
    paymentFrequency,
    updatedPaymentFrequency,
    isPaymentFrequencyDisabled,
  } = policy

  const handleFrequencyChange = useCallback(value => {
    if (value === paymentFrequency?.value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(actionCreators.resetPaymentFrequency(bancsPolicyNo))
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(actionCreators.alterPaymentFrequency(value, bancsPolicyNo))
    }
    // trigger API to fetch new premiums, API call will only occur if
    // alteration is done in the quote
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionCreators.altsCalculateQuote((err: object) => {
        const apiEvent = createEvent({
          Splunk: {
            attributes: {
              'workflow.name': 'Premium calculator fetching new premium',
              error: !!err,
            },
          },
        })
        apiEvent.end()
      })
    )
  }, [])

  const toggleWaiver = (value: 'Yes' | 'No') => {
    if (value === 'No') {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.altsRemoveBenefit(
          {
            type: waiverBenefit?.type,
            benefitInstanceNo: waiverBenefit?.benefitInstanceNo,
            name: waiverBenefit?.name,
          },
          bancsPolicyNo,
          policyInstanceNo
        )
      )
      // trigger API to fetch new premiums, API call will only occur if
      // alteration is done in the quote
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.altsCalculateQuote((err: object) => {
          const apiEvent = createEvent({
            Splunk: {
              attributes: {
                'workflow.name': 'Premium calculator fetching new premium',
                error: !!err,
              },
            },
          })
          apiEvent.end()
        })
      )
    } else {
      dispatch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        actionCreators.resetRemoveSumInsured(
          {
            type: waiverBenefit?.type,
            benefitInstanceNo: waiverBenefit?.benefitInstanceNo,
          },
          bancsPolicyNo,
          policyInstanceNo
        )
      )
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(actionCreators.calculateAlteredPoliciesInQuote())
    }
  }

  const fetchMultiCoverDiscountChipText = (isExisting: boolean) => {
    if (isExisting || policy.newMultiCoverDiscount === policy.multiCoverDiscount) {
      return ''
    }

    if (policy.newMultiCoverDiscount === 0) {
      return fields.Ineligible.value
    }

    return fields.Edited.value
  }

  const renderPolicyData = (isExisting: boolean) => {
    const policyData = [
      {
        key: fields.TotalPremiums,
        val: isExisting
          ? `${policy?.totalPremium} ${policy?.paymentFrequency?.label as string}`
          : `${policy?.newTotalPremium as string} ${
              policy?.updatedPaymentFrequency?.label as string
            }`,
      },
      ...(policy?.isWaiverPresent
        ? [
            {
              key: policy.waiverName,
              val: isExisting ? policy.waiverPremium : policy.newWaiverPremium,
            },
          ]
        : []),
      {
        key: fields.StampDuty,
        val: isExisting ? policy?.stampDuty : policy?.newStampDuty,
      },
      {
        key: fields.TaxRebate,
        val: isExisting ? policy?.taxRebate : policy?.newTaxRebate,
      },
      ...(policy?.multiCoverDiscount
        ? [
            {
              key: fields.MultiCoverDiscount,
              val: `${
                isExisting ? policy?.multiCoverDiscount ?? 0 : policy?.newMultiCoverDiscount ?? 0
              }%`,
              toolTipID: idMaker(
                `${fields.MultiCoverDiscount?.value}${isExisting ? '-existing' : '-new'}`
              ),
              toolTipContent: fields?.MultiCoverDiscountToolTip,
              chipVariant: policy.newMultiCoverDiscount === 0 ? 'removed' : 'default',
              chipText: fetchMultiCoverDiscountChipText(isExisting),
            },
          ]
        : []),
      ...(policy?.vivoDiscount
        ? [
            {
              key: fields.VivoIncentiveDiscount,
              val: `${policy?.vivoDiscount}%`,
              toolTipID: idMaker(
                `${fields.VivoIncentiveDiscount?.value}${isExisting ? '-existing' : '-new'}`
              ),
              toolTipContent: fields?.VivoIncentiveDiscountToolTip,
            },
          ]
        : []),
      {
        key: fields.PolicyFee,
        val: isExisting ? policy?.policyFee : policy?.newPolicyFee,
      },
      {
        key: isExisting ? fields.ExistingPremium : fields.NewPremium,
        val: isExisting
          ? `${policy?.policyPremium} ${policy?.paymentFrequency?.label as string}`
          : `${policy?.newPolicyPremium as string} ${
              policy?.updatedPaymentFrequency?.label as string
            }`,
      },
    ]
    return policyData
  }

  const renderPolicyItem = (field: {
    key: SitecoreField | string | undefined
    val?: string | number
    toolTipID?: string
    toolTipContent?: SitecoreField | string
    chipText?: string
    chipVariant?: string
  }) => (
    <>
      <dt>
        {renderTextField(field.key)}
        {field?.toolTipContent && (
          <TooltipContainer>
            <span data-tip data-for={field?.toolTipID}>
              <Icon iconName={['far', 'circle-info']} />
            </span>
            <Tooltip id={field?.toolTipID} offset={{ bottom: 15 }}>
              {renderTextField(field?.toolTipContent, true)}
            </Tooltip>
          </TooltipContainer>
        )}
      </dt>
      <dd>
        <span>{field.val}</span>
        {field.chipText && <Chip variant={field.chipVariant}>{field.chipText}</Chip>}
      </dd>
    </>
  )

  return (
    <PolicyContainer>
      <PolicyHeading>
        <Heading variant="h3" size="medium">
          {fields.Policy?.value.replace('##', policy.policyNo)}
          <Chip variant={policy.policyTypeChipVariant}>{policy.policyType}</Chip>
        </Heading>
        <SubHeading>
          <PaymentDetails>
            <span>{`${fields.PaymentMethod?.value}:`}</span>
            <span>{policy.paymentMethod}</span>
          </PaymentDetails>
          {policy.waiverBenefit && (
            <WaiverWrapper>
              <p>{renderTextField(fields.PremiumWaiver)}</p>
              <RadioGroup type="inline" styleOverrides={styles.radioGroup}>
                <Radio
                  name={`premiemWaiver${policy.policyNo}`}
                  htmlFor={`premiumWavierYes${policy.policyNo}`}
                  text={fields.Yes?.value}
                  value="Yes"
                  checked={policy.waiverSelected}
                  handleOnChange={toggleWaiver}
                />
                <Radio
                  name={`premiemWaiver${policy.policyNo}`}
                  htmlFor={`premiumWavierNo${policy.policyNo}`}
                  text={fields.No?.value}
                  value="No"
                  checked={!policy.waiverSelected}
                  handleOnChange={toggleWaiver}
                />
              </RadioGroup>
            </WaiverWrapper>
          )}
        </SubHeading>
      </PolicyHeading>
      <PaymentFrequency>
        <>
          <span>{`${fields.PaymentFrequency?.value}`}</span>
          <RadioGroup type="inline" styleOverrides={styles.radioGroup}>
            {paymentFrequencyOptions.map(frequency => (
              <Radio
                name={`paymentFrequency${policy.policyNo}`}
                htmlFor={`paymentFrequency${frequency.value}${policy.policyNo}`}
                text={frequency.label}
                value={frequency.value}
                checked={frequency.value === updatedPaymentFrequency?.value}
                handleOnChange={handleFrequencyChange}
                disabled={isPaymentFrequencyDisabled}
              />
            ))}
          </RadioGroup>
        </>
      </PaymentFrequency>
      <CoverSection isPolicyAltered={isPolicyAltered}>
        <div>
          <Heading variant="h3" size="medium">
            {renderTextField(fields.Existing)}
          </Heading>
          {policyPreview.covers.map(cover => (
            <Cover
              isExisting
              fields={fields}
              cover={cover}
              productId={policyPreview.policy.productId}
            />
          ))}
        </div>
        <div>
          <Heading variant="h3" size="medium">
            {renderTextField(fields.New)}
          </Heading>
          {(policyPreview?.covers || []).map(cover => (
            <Cover
              isExisting={false}
              fields={fields}
              cover={cover}
              productId={policyPreview.policy.productId}
            />
          ))}
        </div>
      </CoverSection>
      <PolicySection>
        <div>
          <dl>{renderPolicyData(true).map(item => renderPolicyItem(item))}</dl>
        </div>
        {isPolicyAltered && (
          <div>
            <dl>{renderPolicyData(false).map(item => renderPolicyItem(item))}</dl>
          </div>
        )}
      </PolicySection>
      {isPolicyAltered ? (
        <PremiumSaved>
          <div>
            {renderTextField(
              policy?.hasPremiumIncreased ? fields.IncreasedPremium : fields.SavePremium
            )}
          </div>
          <div>
            <span>{fields.Estimate.value}</span>
            {policy.showSaving && (
              <span>{` ${policy.saving || ''} ${
                policy.updatedPaymentFrequency?.label as string
              }`}</span>
            )}
            <span>
              {policy.annualSaving
                ? `${policy.showSaving ? ' /' : ''} ${policy.annualSaving} ${
                    REPORTING_PAYMENT_FREQUENCY_OPTIONS.find(
                      frequency => frequency.value === POLICY_FREQUENCY_ANNUAL
                    )?.label?.toLowerCase() || ''
                  }`
                : null}
            </span>
          </div>
        </PremiumSaved>
      ) : (
        <NoAdjustmentContainer>
          <Icon iconName={['far', 'circle-info']} />
          {renderTextField(fields.NoAdjustment)}
        </NoAdjustmentContainer>
      )}
      {isPolicyAltered && policy?.hasPremiumIncreased && (
        <ErrorMessage>
          <Icon iconName={['far', 'triangle-exclamation']} />
          <span>{renderTextField(fields.IncreasedPremiumNotification, true)}</span>
        </ErrorMessage>
      )}
    </PolicyContainer>
  )
}

export default Policy
