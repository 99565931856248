import 'core-js/es7/array'
import get from 'lodash/get'

import {
  UPDATE_RELATIONSHIP_FROM_POLICY,
  UPDATE_RELATIONSHIP_FROM_POLICIES_SUMMARY,
} from '../actions/types/customerRelationships'
import { CUSTOMER_DETAILS_FETCH_SUCCESS } from '../actions/types/customerPersonalDetails'
import { OKTA_RESET_AUTHENTICATION_STATE } from '../actions/types/authentication'

// FIXME: currently API's return these values in two different formats.
// this should be rmoved when formats are consistent
const genderMap = {
  F: 'Female',
  Female: 'Female',
  M: 'Male',
  Male: 'Male',
  U: 'Unknown',
  Unknown: 'Unknown',
}

const smokingMap = {
  Y: 'Yes',
  N: 'No',
  No: 'No',
  Yes: 'Yes',
  UNK: 'Unknown',
  Unknown: 'Unknown',
}

export const initialState = {}
const customerRelationships = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RELATIONSHIP_FROM_POLICIES_SUMMARY:
    case UPDATE_RELATIONSHIP_FROM_POLICY: {
      const relationships = action.payload
      return relationships.reduce((newState, relationship) => {
        const { bancsCustomerNo, bancsPolicyNo, specialAgentType, ...other } = relationship

        const currentBancsCustomerNo = newState[bancsCustomerNo] || {}
        const currentPolicyRolesMap = get(currentBancsCustomerNo, 'policyRolesMap', {})
        const currentPolicyMeta = get(currentPolicyRolesMap[bancsPolicyNo], 'policyMeta', {})
        const isTrueSource = other.expiryDate === '9999-01-01'
        return {
          ...newState,
          [bancsCustomerNo]: {
            ...currentBancsCustomerNo,
            loadingState: currentBancsCustomerNo.loadingState || 'UNLOADED',
            bancsCustomerNo,
            ...(other.roleCode === 'LA' && {
              title: get(other, 'title', currentBancsCustomerNo.title),
              firstName: get(other, 'firstName', currentBancsCustomerNo.firstName),
              lastName: get(other, 'lastName', currentBancsCustomerNo.lastName),
              smokerStatus:
                smokingMap[get(other, 'smokerStatus', currentBancsCustomerNo.smokerStatus)],
              gender: genderMap[get(other, 'gender', currentBancsCustomerNo.gender)],
            }),
            contactMethods: {
              ...(currentBancsCustomerNo.contactMethods || {
                addresses: [],
                emails: [],
                phones: [],
              }),
            },
            hasRelatedParty: currentBancsCustomerNo.hasRelatedParty || false,
            hasOdsLoaded: currentBancsCustomerNo.hasOdsLoaded || false,
            retryCount: currentBancsCustomerNo.retryCount || 0,
            ...(specialAgentType && {
              specialAgentType,
            }),
            policyRolesMap: {
              ...currentPolicyRolesMap,
              [bancsPolicyNo]: {
                ...currentPolicyRolesMap[bancsPolicyNo],
                policyNo: bancsPolicyNo,
                policyId: other.policyId,
                policyMeta: {
                  ...currentPolicyMeta,
                  ...(other.roleCode === 'LA' && {
                    exclusions: get(relationship, 'benefitMeta', [])
                      .map(benefit =>
                        (benefit.exclusions || []).map(exclusion => ({
                          ...exclusion,
                          benefitStatus: benefit.benefitStatus,
                          benefitType: benefit.type,
                        }))
                      )
                      .filter(Boolean),
                    loadings: get(relationship, 'benefitMeta', [])
                      .flatMap(benefit => benefit.loadings)
                      .filter(Boolean),
                    benefits: get(relationship, 'benefitMeta', []).map(benefit => benefit.type),
                    isPrimaryLifeAssured: relationship.isPrimaryLifeAssured,
                  }),
                  ...(other.roleCode === 'OWR' && {
                    isPrimaryPolicyOwner: relationship.isPrimaryPolicyOwner,
                  }),
                  ...(other.roleCode === 'LB' &&
                    // conditional check to filter out noise created by duplicate LB.
                    // we only want to follow this logic for non-binding LB's though
                    (isTrueSource || relationship.bindingType !== 'NBND') && {
                      bindingType: relationship.bindingType,
                      percentageAllocation: relationship.percentageAllocation,
                      beneficiaryRelationshipType: relationship.beneficiaryRelationshipType,
                    }),
                  status: relationship.status,
                  startDate: relationship.startDate,
                  expiryDate: relationship.expiryDate,
                },
                roles: get(currentPolicyRolesMap[bancsPolicyNo], 'roles', []).concat(
                  relationship.roleCode
                ), // FIXME: Todo, have validator to indicate dupe data.
              },
            },
          },
        }
      }, state)
    }

    case 'CUSTOMER_RELATEDPARTY_SELF_LOADING': {
      const bancsCustomerNo = action.payload
      const currentBancsCustomerNo = state[bancsCustomerNo] || {}
      return {
        ...state,
        [bancsCustomerNo]: {
          ...currentBancsCustomerNo,
          loadingState: 'LOADING',
        },
      }
    }

    case 'CUSTOMER_RELATEDPARTY_SELF_FAILED': {
      const bancsCustomerNo = action.payload
      const currentBancsCustomerNo = state[bancsCustomerNo] || {}

      const currentRetryConut = currentBancsCustomerNo.retryCount + 1
      return {
        ...state,
        [bancsCustomerNo]: {
          ...currentBancsCustomerNo,
          retryCount: currentRetryConut,
          loadingState:
            currentRetryConut > 2 || action.noRetry
              ? 'FAILED'
              : currentBancsCustomerNo.loadingState,
        },
      }
    }

    case CUSTOMER_DETAILS_FETCH_SUCCESS:
    case 'CUSTOMER_RELATEDPARTY_SELF_SUCCESS': {
      const { odsConditionMet = false } = action
      const { businessData, bancsCustomerNo: bancsCustomerNoFromStore } = action.payload
      let bancsCustomerNo
      let relatedParty = {}
      if (action.type === 'CUSTOMER_RELATEDPARTY_SELF_SUCCESS') {
        if (!businessData.relatedParty) {
          /**
           *  This is for beneficiary edge case where after adding a
           * beneficiary there is a 15 minute delay for the backend to synchronise,
           *  No way around this unfortunately
           *
           */
          return {
            ...state,
            [bancsCustomerNoFromStore]: {
              ...state[bancsCustomerNoFromStore],
              hasRelatedParty: true,
              loadingState: 'LOADED',
              relatedPartyStillPending: businessData.relatedParty === null,
            },
          }
        }
        // We are deconstructing to a let variable
        ;({ bancsCustomerNo, ...relatedParty } = businessData.relatedParty)
      } else if (action.type === CUSTOMER_DETAILS_FETCH_SUCCESS) {
        relatedParty = businessData.member || {}
        bancsCustomerNo = relatedParty.identifiers[0].value
      }
      const currentBancsCustomerNo = state[bancsCustomerNo] || {}
      const level1 = get(businessData, 'level1', null)
      /**
       * This is a bit awkward but it sets hasOdsLoaded to true
       * if related party api was called in context of all policies loaded.
       * Flag indicates if query has finished determining when to fetch level1/level2 from ODS.
       *  */

      let hasOdsLoaded = currentBancsCustomerNo.hasOdsLoaded || false
      if (level1) {
        hasOdsLoaded = true
      } else if (action.type === 'CUSTOMER_RELATEDPARTY_SELF_SUCCESS') {
        hasOdsLoaded = odsConditionMet
      }

      return {
        ...state,
        [bancsCustomerNo]: {
          ...currentBancsCustomerNo,
          partyNo:
            (relatedParty && relatedParty.partyNo) ||
            get(relatedParty, 'identifiers', [{ type: 'PARTY_NO', value: undefined }]).find(
              id => id.type === 'PARTY_NO'
            ).value,
          policyRolesMap: currentBancsCustomerNo.policyRolesMap || {},
          hasRelatedParty: true,
          bancsCustomerNo,
          retryCount: 0,
          loadingState: 'LOADED',
          title: relatedParty.title,
          firstName: relatedParty.firstName,
          middleName: relatedParty.middleName,
          businessName: relatedParty.businessName,
          lastName: relatedParty.lastName,
          smokerStatus: relatedParty.isSmoker,
          gender: relatedParty.gender,
          partyType: relatedParty.partyType,
          dateOfBirth: relatedParty.dateOfBirth,
          abnNumber: relatedParty.abnNumber,
          hasOdsLoaded,
          ...(level1 && {
            level1,
          }),
          // @FIXME: we dump everything in store
          // due to having to submit everything back to the backend
          // Below fields are also needed in order to construct PolicyEntity>relationship
          companyIdentifier: relatedParty.companyIdentifier,
          initial: relatedParty.initial,
          isTFNProvided: relatedParty.isTFNProvided,
          maritalStatus: relatedParty.maritalStatus,
          mlVerificationStatus: relatedParty.mlVerificationStatus,
          preferredCommChannel: relatedParty.preferredCommChannel,
          specialCorroRequirement: relatedParty.specialCorroRequirement,
          fatcacrsentity: relatedParty.fatcacrsentity,
          employment: relatedParty.employment,
          lobPartyDetails: relatedParty.lobPartyDetails,
          contactMethods: relatedParty.contactMethods,
        },
      }
    }

    case OKTA_RESET_AUTHENTICATION_STATE: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export const customerRelationshipsState = state => state.customerRelationships
export default customerRelationships
